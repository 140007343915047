<template>
  <!-- 提示文案类 -->
  <div v-if="contentType === 'array'">
    <div class="chat-tips" v-for="(item, index) in message.content" :key="index">
      <span class="chat-tips__content">{{ item.desc }}</span>
    </div>
  </div>
  <div v-else>
    <div class="chat-tips">
      <span class="chat-tips__content" v-html="message.content"></span>
    </div>
  </div>
</template>

<script>
import typeOf from '@/utils/typeof'

export default {
  name: 'BubbleTip',
  props: {
    message: Object
  },
  computed: {
    contentType () {
      return typeOf(this.message.content)
    }
  }
}
</script>
